import React from 'react';
import {Service_data} from "../../fakedata/data"
import IconAir from './air-icon.png'
import IconExpress from './express-icon.png'
import IconTransport from './transport-icon.png'

const icons =[
    IconAir,
    IconExpress,
    IconTransport
]
const Services = () => {
    return (
        <div className="services">
            <h2>{Service_data.title}</h2>
            {/*<p>{Service_data.desc}</p>*/}
            <ul>
                {
                    Service_data.service.map((item,idx)=>{
                        return(
                            <li key={item.id}>
                                <div className="services_title">
                                    <div className="services_icon">
                                        <img src={icons[idx]} alt="service-icon"/>
                                    </div>
                                    <h3>{item.title}</h3>
                                </div>

                                <p>{item.desc}</p>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    );
};

export default Services;