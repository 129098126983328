import React,{Component}  from "react";
import {optionsKg} from "./data";
import Select from "react-select";

const customStylesSelect = {
    option: (provided, state) => {
        return ({
            ...provided,
            color: '#707070',
            backgroundColor: state.isFocused ? '#eaeaea' : '#F2F2F2',
            padding: 10,
            margin: 0,
            ':active': {
                backgroundColor: '#eaeaea',
            }
        })
    },
    menu: (style) => ({
        ...style,
        margin: 0,
        backgroundColor: '#F2F2F2',
        border: 'none',
        boxShadow: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderTop: '1px solid hsl(0,0%,80%)'
    }),
    control: (style, state) => {
        return ({
            backgroundColor: '#F2F2F2',
            borderRadius: 5,
            display: 'flex',
            padding: '.375rem 0',
            borderBottomLeftRadius: state.menuIsOpen ? 0 : '5px',
            borderBottomRightRadius: state.menuIsOpen ? 0 : '5px',
        })
    },
    placeholder: () => ({
        fontFamily: 'Montserrat-Medium',
        fontSize: '14px',
        color: '#707070',
        opacity: '.45'
    })

};

class MyCountrySelect extends Component {
    handleChange = value => {
        this.props.onChange("size", value);
    };

    handleBlur = () => {
        this.props.onBlur("size", true);
    };
    render() {
        return (
            <React.Fragment>
                <Select
                    options={this.props.countryOptions}
                    isMulti={false}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    // value={this.props.value}
                    placeholder={"Ընտրել երկիր/քաղաք"}
                    styles={customStylesSelect}
                    noOptionsMessage={() => "բացակայում է, Զանգահարեք 041 511-515"}
                />
            </React.Fragment>
        );
    }
}
class MySelect extends Component {
    handleChange = value => {
        this.props.onChange(`rule[${this.props.index}].select`, value);
    };

    handleBlur = () => {
        this.props.onBlur(`rule[${this.props.index}].select`, true);
    };

    render() {
        return (
            <div>
                <Select
                    id="color"
                    options={optionsKg}
                    isMulti={false}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    // value={this.props.value}
                    placeholder={"կգ"}
                    styles={customStylesSelect}
                    noOptionsMessage={() => "Ընտրեք քաշը"}
                />
            </div>
        );
    }
}

export {
    MyCountrySelect,
    MySelect
}