import React from 'react';
import {About_Us_data} from "../../fakedata/data"
const AboutUs = () => {
    return (
        <div className="about_us">
            <h2>{About_Us_data.title}</h2>
            <p>{About_Us_data.desc}</p>
        </div>
    );
};

export default AboutUs;