import React from 'react';
import {Switch} from 'react-router-dom'
import {Route} from "react-router-dom";
import NotFound from "./Components/not_found";
import Home from "./home";
const Routes = () => {
    return (
        <div>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
};

export default Routes;