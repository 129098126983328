import {Field, FieldArray, Form, getIn, withFormik} from "formik";
import React from "react";
import Grid from '@material-ui/core/Grid';
import {MyCountrySelect,MySelect} from './selectForm'
import * as Yup from "yup";
import {optionsCountry, price} from "./data";

const ErrorMessage = ({name}) => (
    <Field
        name={name}
        render={({form}) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            if (name === "size") {
                return touch && error ? "Ընտրեք քաղաքը" : null;
            }
            if (name.includes("select")) {
                return touch && error ? "Ընտրեք քաշը" : null;
            }
            if (name.includes("num1")) {
                return touch && error ? "Մուտքագրեք" : null;
            }
            if (name.includes("num2")) {
                return touch && error ? "Մուտքագրեք" : null;
            }
            if (name.includes("num3")) {
                return touch && error ? "Մուտքագրեք" : null;
            }
            return touch && error ? error : null;
        }}
    />
);
const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        rule: Yup.array().of(
            Yup.object().shape({
                num1: Yup.number()
                    .required("Required")
                    .min(1, "too short"),
                num2: Yup.number()
                    .positive("positive")
                    .min(1, "num2")
                    .required("Required"),
                num3: Yup.number()
                    .positive("positive")
                    .min(1, "num3")
                    .required("Required"),
                select: Yup.object({
                    value: Yup.string().required(),
                    label: Yup.string().required()
                })
            })
        ),
        size: Yup.object()
            .shape({
                value: Yup.string().required(),
                label: Yup.string().required(),
                id: Yup.string().required()
            })
            .required("Required object")
    }),
    mapPropsToValues: props => {
        return {
            open: props.user.open,
            size: {},
            topics: [],
            rule: [
                {
                    num1: '',
                    num2: '',
                    num3: '',
                    select: {}
                }
            ]
        };
    },
    handleSubmit: (values, bag) => {
        values.open = true;
        const payload = {
            ...values,
            topics: values.rule.map(t => {
                const maxCount =
                    t.select.value > (t.num1 * t.num2 * t.num3) / 5000
                        ? Number(t.select.value)
                        : Math.ceil(Number((t.num1 * t.num2 * t.num3) / 5000));
                const priceExp = bag.props.exp === "one" ? "export" : "import";
                const beforeLastObjectId = price[priceExp][values.size.id].length - 2;
                const beforeLastObject = price[priceExp][values.size.id][beforeLastObjectId];
                const beforeLastObjectValue = Number(beforeLastObject.value);
                const beforeLastObjectPrice = Number(beforeLastObject.price);
                const priceKg = price[priceExp][values.size.id].find(function (item) {
                    if (Number(item.value) !== Number(maxCount)) {
                        return item.value === 'more'
                    } else {
                        return Number(item.value) === Number(maxCount);
                    }

                });
                const priceMore = (maxCount - beforeLastObjectValue) * priceKg.price + beforeLastObjectPrice
                const priceMain = priceKg.value === 'more' ? priceMore : priceKg.price
                return {
                    localkg: maxCount,
                    price: priceMain
                };
            })
        };
        setTimeout(function () {
            // alert(JSON.stringify(payload, null, 2));
            bag.setSubmitting(false);
            bag.props.updateUser(payload);
        }, 500);
    },

    displayName: "MyForm"
});

const CustomInputComponent = ({
                                  field,
                                  value,
                                  form: {touched, values, setFieldValue, setFieldTouched, errors},
                                  ...props
                              }) => {
    return (
        <div>
            <MySelect
                value={values.rule[props.index].select}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                error={errors.rule} MySelect
                touched={touched.rule}
                index={props.index}
            />
        </div>
    );
};
const MyForm = props => {
    const {
        values,
        touched,
        // dirty,
        errors,
        // handleChange,
        // handleBlur,
        handleSubmit,
        // handleReset,
        setFieldValue,
        setFieldTouched,
        // isSubmitting,
        exp
    } = props;
    return (
        <div>
            {/*<h1>{exp === 'one' ? 'Export' : 'Import'}</h1>*/}
            <Form onSubmit={handleSubmit}>
                <div className="calc_field_input calc_select_country">
                    <label>{exp === "one"? 'Դեպի ուր':'Որտեղից'}</label>
                    <MyCountrySelect
                        value={values.size}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.size}
                        touched={touched.size}
                        countryOptions={
                            exp === "one"
                                ? optionsCountry.country
                                : optionsCountry.countryImport
                        }
                    />
                    <div className="calc_field_error">
                        <ErrorMessage name="size"/>
                    </div>
                </div>

                <FieldArray
                    name="rule"
                    render={arrayHelpers => {
                        return (
                            <div className="calc_first_form_wrap">
                                {values.rule && values.rule.length > 0 ? (
                                    values.rule.map((friend, index) => (
                                        <div key={index}>
                                            {values.rule.length !== 1 ? (
                                                <div className="calc_del_request_wrapper">
                                                    <div className="calc_request">
                                                        {index+1} Հարցում
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.remove(index)}
                                                        className="calc_btn_del"
                                                    >
                                                        x
                                                    </button>
                                                </div>
                                            ) : null}
                                            <Grid
                                                container
                                                spacing={16}
                                                style={{
                                                    paddingTop:21
                                                }}
                                            >
                                                <Grid item xs={6} md={3} className="calc_field_input">
                                                    <label>քաշ <span>կգ</span></label>
                                                    <Field
                                                        name={`rule.${index}.select`}
                                                        component={CustomInputComponent}
                                                        index={index}
                                                    />
                                                    <div style={{color: "red", position: "absolute"}}>
                                                        <ErrorMessage name={`rule.${index}.select`}/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} md={3} className="calc_field_input">
                                                    <label htmlFor="num1">բարձր․ <span>սմ</span></label>
                                                    <Field
                                                        id="num1"
                                                        name={`rule.${index}.num1`}
                                                        min="1"
                                                        type="number"
                                                        placeholder="սմ"
                                                    />
                                                    <div className="calc_field_error">
                                                        <ErrorMessage name={`rule.${index}.num1`}/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} md={3} className="calc_field_input">
                                                    <label htmlFor="num2">երկար․ <span>սմ</span></label>
                                                    <Field
                                                        id="num2"
                                                        name={`rule.${index}.num2`}
                                                        min="1"
                                                        type="number"
                                                        placeholder="սմ"
                                                    />
                                                    <div className="calc_field_error">
                                                        <ErrorMessage name={`rule.${index}.num2`}/>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6} md={3} className="calc_field_input">
                                                    <label htmlFor="num3">լայն․ <span>սմ</span></label>
                                                    <Field
                                                        id="num3"
                                                        name={`rule.${index}.num3`}
                                                        min="1"
                                                        type="number"
                                                        placeholder="սմ"
                                                    />
                                                    <div className="calc_field_error">
                                                        <ErrorMessage name={`rule.${index}.num3`}/>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>

                                    ))
                                ) : (
                                    <button type="button" onClick={() => arrayHelpers.push("")}>
                                        Add a field
                                    </button>
                                )}
                                <div className="calc_submit_plus_wrapper">
                                    <button
                                        type="button"
                                        className="calc_btn_plus"
                                        onClick={() =>
                                            arrayHelpers.push({
                                                num1: '',
                                                num2: '',
                                                num3: '',
                                                select: {}
                                            })
                                        }
                                    >
                                        +
                                    </button>
                                    <button type="submit" className="btn_submit font-medium calc_btn_submit">Հարցում
                                    </button>
                                </div>
                            </div>
                        );
                    }}
                />
            </Form>
        </div>
    );
};

const MyEnhancedForm = formikEnhancer(MyForm);
export default MyEnhancedForm