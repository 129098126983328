import React from 'react';

const Footer = () => {
    return (
        <footer className="font-medium">
            <p>ԲՈԼՈՐ ԻՐԱՎՈՒՆՔՆԵՐԸ ՊԱՇՏՊԱՆՎԱԾ ԵՆ. <span>Express Courier</span></p>
        </footer>
    );
};

export default Footer;