const optionsCountry = {
    country: [
        {
            label: "Ռուսաստան",
            flag: "ru",
            options: [
                { value: "abk", label: "Աբական", id: "c" },
                { value: "arx", label: "Արխանգելսկ", id: "b" },
                { value: "asx", label: "Աստրախան", id: "b" },
                { value: "brl", label: "Բառնաուլ", id: "b" },
                { value: "blg", label: "Բելգորոդ", id: "b" },
                { value: "bgk", label: "Բլագովեշչենսկ", id: "c" },
                { value: "brk", label: "Բրյանսկ", id: "b" },
                { value: "nov", label: "Մեծ Նովգորոդ", id: "b" },
                { value: "vld", label: "Վլադիվոստոկ", id: "c" },
                { value: "vlk", label: "Վլադիկավկազ", id: "b" },
                { value: "vlm", label: "Վլադիմիր", id: "b" },
                { value: "vlg", label: "Վոլգոգրադ", id: "b" },
                { value: "vog", label: "Վոլոգդա", id: "b" },
                { value: "vor", label: "Վորոնեժ", id: "b" },
                { value: "grz", label: "Գրոզնի", id: "b" },
                { value: "ekt", label: "Եկատերինբուրգ", id: "b" },
                { value: "ivn", label: "Իվանովո", id: "b" },
                { value: "ijs", label: "Իժևսկ", id: "b" },
                { value: "irk", label: "Իրկուտսկ", id: "c" },
                { value: "kaz", label: "Կազան", id: "b" },
                { value: "kal", label: "Կալինինգրադ", id: "b" },
                { value: "kag", label: "Կալուգա", id: "b" },
                { value: "kem", label: "Կեմերովո", id: "b" },
                { value: "kir", label: "Կիրով", id: "b" },
                { value: "kog", label: "Կոգալիմ", id: "c" },
                { value: "kos", label: "Կոստրոմա", id: "b" },
                { value: "krs", label: "Կրասնոդար", id: "b" },
                { value: "knk", label: "Կրասնոյարսկ", id: "b" },
                { value: "kgn", label: "Կուրգան", id: "b" },
                { value: "krk", label: "Կուրսկ", id: "b" },
                { value: "lip", label: "Լիպեցկ", id: "b" },
                { value: "mgd", label: "Մագադան", id: "c" },
                { value: "mgk", label: "Մագնիտոգորսկ", id: "c" },
                { value: "mxa", label: "Մախաչկալա", id: "b" },
                { value: "min", label: "Մինվոդի", id: "c" },
                { value: "msk", label: "Մոսկվա", id: "a" },
                { value: "mur", label: "Մուրմանսկ", id: "b" },
                { value: "nbn", label: "Նաբերեժնիե Չելնի", id: "b" },
                { value: "nal", label: "Նալչիկ", id: "b" },
                { value: "nij", label: "Նիժնևարտովսկ", id: "c" },
                { value: "nin", label: "Նիժնի Նովգորոդ", id: "b" },
                { value: "nok", label: "Նովոկուզնեցկ", id: "c" },
                { value: "nov", label: "Նովոռոսիյսկ", id: "b" },
                { value: "nsb", label: "Նովոսիբիրսկ", id: "b" },
                { value: "nun", label: "Նոր Ուրենգոյ", id: "c" },
                { value: "nrs", label: "Նորիլսկ", id: "c" },
                { value: "noy", label: "Նոյաբրսկ", id: "c" },
                { value: "omsk", label: "Օմսկ", id: "b" },
                { value: "orl", label: "Օրյոլ", id: "b" },
                { value: "orb", label: "Օրենբուրգ", id: "b" },
                { value: "pez", label: "Պենզա", id: "b" },
                { value: "prm", label: "Պերմ", id: "b" },
                { value: "ptr", label: "Պետրոզավոդսկ", id: "b" },
                { value: "psk", label: "Պսկով", id: "b" },
                { value: "ros", label: "Ռոստով", id: "b" },
                { value: "raz", label: "Ռյազան", id: "b" },
                { value: "sam", label: "Սամարա", id: "b" },
                { value: "stp", label: "Սանկտ Պետերբուրգ", id: "a" },
                { value: "srs", label: "Սարանսկ", id: "b" },
                { value: "srt", label: "Սարատով", id: "b" },
                { value: "smf", label: "Սիմֆերոպոլ", id: "b" },
                { value: "sml", label: "Սմոլենսկ", id: "b" },
                { value: "soi", label: "Սոչի", id: "b" },
                { value: "stv", label: "Ստավրոպոլ", id: "b" },
                { value: "sur", label: "Սուրգուտ", id: "b" },
                { value: "sik", label: "Սիկտիրվկար", id: "b" },
                { value: "tmb", label: "Տամբով", id: "b" },
                { value: "tvr", label: "Տվեր", id: "b" },
                { value: "tol", label: "Տոլյատի", id: "b" },
                { value: "tmk", label: "Տոմսկ", id: "b" },
                { value: "tul", label: "Տուլա", id: "b" },
                { value: "tym", label: "Տյումեն", id: "b" },
                { value: "ulu", label: "Ուլան-Ուդե", id: "c" },
                { value: "ulv", label: "Ուլյանովսկ", id: "b" },
                { value: "usk", label: "Ուսինսկ", id: "c" },
                { value: "ufa", label: "Ուֆա", id: "b" },
                { value: "uxt", label: "Ուխտա", id: "b" },
                { value: "xab", label: "Խաբարովսկ", id: "c" },
                { value: "xam", label: "Խանտի-Մանսիյսկ", id: "c" },
                { value: "ceb", label: "Չեբոքսարի", id: "b" },
                { value: "cel", label: "Չելյաբինսկ", id: "b" },
                { value: "cer", label: "Չերեպովեցկ", id: "b" },
                { value: "cit", label: "Չիտա", id: "c" },
                { value: "sax", label: "Յուժնո-Սախալինսկ", id: "c" },
                { value: "yak", label: "Յակուտսկ", id: "c" },
                { value: "yar", label: "Յարոսլավոլ", id: "b" }
            ]
        },
        {
            label: "Բելառուս",
            flag: "br",
            options: [
                { value: "msk", label: "Մինսկ", id: "b" }
            ]
        },
        {
            label: "Ուկրաինա",
            flag: "ukr",
            options: [
                { value: "kiv", label: "Կիև", id: "b" }
            ]
        },
        {
            label: "Ղազախստան",
            flag: "kaz",
            options: [
                { value: "alm", label: "Ալմաթի", id: "c" },
                { value: "ast", label: "Աստանա", id: "c" }
            ]
        }
    ],
    countryImport: [
        {
            label: "Ռուսաստան",
            flag: "ru",
            options: [
                { value: "abk", label: "Աբական", id: "c" },
                { value: "arx", label: "Արխանգելսկ", id: "b" },
                { value: "asx", label: "Աստրախան", id: "b" },
                { value: "brl", label: "Բառնաուլ", id: "b" },
                { value: "blg", label: "Բելգորոդ", id: "b" },
                { value: "bgk", label: "Բլագովեշչենսկ", id: "c" },
                { value: "brk", label: "Բրյանսկ", id: "b" },
                { value: "nov", label: "Մեծ Նովգորոդ", id: "b" },
                { value: "vld", label: "Վլադիվոստոկ", id: "c" },
                { value: "vlk", label: "Վլադիկավկազ", id: "b" },
                { value: "vlm", label: "Վլադիմիր", id: "b" },
                { value: "vlg", label: "Վոլգոգրադ", id: "b" },
                { value: "vog", label: "Վոլոգդա", id: "b" },
                { value: "vor", label: "Վորոնեժ", id: "b" },
                { value: "grz", label: "Գրոզնի", id: "b" },
                { value: "ekt", label: "Եկատերինբուրգ", id: "b" },
                { value: "ivn", label: "Իվանովո", id: "b" },
                { value: "ijs", label: "Իժևսկ", id: "b" },
                { value: "irk", label: "Իրկուտսկ", id: "c" },
                { value: "kaz", label: "Կազան", id: "b" },
                { value: "kal", label: "Կալինինգրադ", id: "b" },
                { value: "kag", label: "Կալուգա", id: "b" },
                { value: "kem", label: "Կեմերովո", id: "b" },
                { value: "kir", label: "Կիրով", id: "b" },
                { value: "kog", label: "Կոգալիմ", id: "c" },
                { value: "kos", label: "Կոստրոմա", id: "b" },
                { value: "krs", label: "Կրասնոդար", id: "b" },
                { value: "knk", label: "Կրասնոյարսկ", id: "b" },
                { value: "kgn", label: "Կուրգան", id: "b" },
                { value: "krk", label: "Կուրսկ", id: "b" },
                { value: "lip", label: "Լիպեցկ", id: "b" },
                { value: "mgd", label: "Մագադան", id: "c" },
                { value: "mgk", label: "Մագնիտոգորսկ", id: "c" },
                { value: "mxa", label: "Մախաչկալա", id: "b" },
                { value: "min", label: "Մինվոդի", id: "c" },
                { value: "msk", label: "Մոսկվա", id: "a" },
                { value: "mur", label: "Մուրմանսկ", id: "b" },
                { value: "nbn", label: "Նաբերեժնիե Չելնի", id: "b" },
                { value: "nal", label: "Նալչիկ", id: "b" },
                { value: "nij", label: "Նիժնևարտովսկ", id: "c" },
                { value: "nin", label: "Նիժնի Նովգորոդ", id: "b" },
                { value: "nok", label: "Նովոկուզնեցկ", id: "c" },
                { value: "nov", label: "Նովոռոսիյսկ", id: "b" },
                { value: "nsb", label: "Նովոսիբիրսկ", id: "b" },
                { value: "nun", label: "Նոր Ուրենգոյ", id: "c" },
                { value: "nrs", label: "Նորիլսկ", id: "c" },
                { value: "noy", label: "Նոյաբրսկ", id: "c" },
                { value: "omsk", label: "Օմսկ", id: "b" },
                { value: "orl", label: "Օրյոլ", id: "b" },
                { value: "orb", label: "Օրենբուրգ", id: "b" },
                { value: "pez", label: "Պենզա", id: "b" },
                { value: "prm", label: "Պերմ", id: "b" },
                { value: "ptr", label: "Պետրոզավոդսկ", id: "b" },
                { value: "psk", label: "Պսկով", id: "b" },
                { value: "ros", label: "Ռոստով", id: "b" },
                { value: "raz", label: "Ռյազան", id: "b" },
                { value: "sam", label: "Սամարա", id: "b" },
                { value: "stp", label: "Սանկտ Պետերբուրգ", id: "a" },
                { value: "srs", label: "Սարանսկ", id: "b" },
                { value: "srt", label: "Սարատով", id: "b" },
                { value: "smf", label: "Սիմֆերոպոլ", id: "b" },
                { value: "sml", label: "Սմոլենսկ", id: "b" },
                { value: "soi", label: "Սոչի", id: "b" },
                { value: "stv", label: "Ստավրոպոլ", id: "b" },
                { value: "sur", label: "Սուրգուտ", id: "b" },
                { value: "sik", label: "Սիկտիրվկար", id: "b" },
                { value: "tmb", label: "Տամբով", id: "b" },
                { value: "tvr", label: "Տվեր", id: "b" },
                { value: "tol", label: "Տոլյատի", id: "b" },
                { value: "tmk", label: "Տոմսկ", id: "b" },
                { value: "tul", label: "Տուլա", id: "b" },
                { value: "tym", label: "Տյումեն", id: "b" },
                { value: "ulu", label: "Ուլան-Ուդե", id: "c" },
                { value: "ulv", label: "Ուլյանովսկ", id: "b" },
                { value: "usk", label: "Ուսինսկ", id: "c" },
                { value: "ufa", label: "Ուֆա", id: "b" },
                { value: "uxt", label: "Ուխտա", id: "b" },
                { value: "xab", label: "Խաբարովսկ", id: "c" },
                { value: "xam", label: "Խանտի-Մանսիյսկ", id: "c" },
                { value: "ceb", label: "Չեբոքսարի", id: "b" },
                { value: "cel", label: "Չելյաբինսկ", id: "b" },
                { value: "cer", label: "Չերեպովեցկ", id: "b" },
                { value: "cit", label: "Չիտա", id: "c" },
                { value: "sax", label: "Յուժնո-Սախալինսկ", id: "c" },
                { value: "yak", label: "Յակուտսկ", id: "c" },
                { value: "yar", label: "Յարոսլավոլ", id: "b" }
            ]
        },
        {
            label: "Բելառուս",
            flag: "br",
            options: [
                { value: "msk", label: "Մինսկ", id: "b" }
            ]
        },
        {
            label: "Ուկրաինա",
            flag: "ukr",
            options: [
                { value: "kiv", label: "Կիև", id: "b" }
            ]
        },
        {
            label: "Ղազախստան",
            flag: "kaz",
            options: [
                { value: "alm", label: "Ալմաթի", id: "c" },
                { value: "ast", label: "Աստանա", id: "c" }
            ]
        }
    ]
};
const optionsKg = [
    { value: "0.5", label: "0.5" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
    { value: "13", label: "13" },
    { value: "14", label: "14" },
    { value: "15", label: "15" },
    { value: "16", label: "16" },
    { value: "17", label: "17" },
    { value: "18", label: "18" },
    { value: "19", label: "19" },
    { value: "20", label: "20" },
    { value: "21", label: "21" },
    { value: "22", label: "22" },
    { value: "23", label: "23" },
    { value: "24", label: "24" },
    { value: "25", label: "25" },
    { value: "26", label: "26" },
    { value: "27", label: "27" },
    { value: "28", label: "28" },
    { value: "29", label: "29" },
    { value: "30", label: "30" },
    { value: "31", label: "31" },
    { value: "32", label: "32" },
    { value: "33", label: "33" },
    { value: "34", label: "34" },
    { value: "35", label: "35" },
    { value: "36", label: "36" },
    { value: "37", label: "37" },
    { value: "38", label: "38" },
    { value: "39", label: "39" },
    { value: "40", label: "40" },
    { value: "41", label: "41" },
    { value: "42", label: "42" },
    { value: "43", label: "43" },
    { value: "44", label: "44" },
    { value: "45", label: "45" },
    { value: "46", label: "46" },
    { value: "47", label: "47" },
    { value: "48", label: "48" },
    { value: "49", label: "49" },
    { value: "50", label: "50" },
    { value: "51", label: "51" },
    { value: "52", label: "52" },
    { value: "53", label: "53" },
    { value: "54", label: "54" },
    { value: "55", label: "55" },
    { value: "56", label: "56" },
    { value: "57", label: "57" },
    { value: "58", label: "58" },
    { value: "59", label: "59" },
    { value: "60", label: "60" },
    { value: "61", label: "61" },
    { value: "62", label: "62" },
    { value: "63", label: "63" },
    { value: "64", label: "64" },
    { value: "65", label: "65" },
    { value: "66", label: "66" },
    { value: "67", label: "67" },
    { value: "68", label: "68" },
    { value: "69", label: "69" },
    { value: "70", label: "70" },
    { value: "71", label: "71" },
    { value: "72", label: "72" },
    { value: "73", label: "73" },
    { value: "74", label: "74" },
    { value: "75", label: "75" },
    { value: "76", label: "76" },
    { value: "77", label: "77" },
    { value: "78", label: "78" },
    { value: "79", label: "79" },
    { value: "80", label: "80" },
    { value: "81", label: "81" },
    { value: "82", label: "82" },
    { value: "83", label: "83" },
    { value: "84", label: "84" },
    { value: "85", label: "85" },
    { value: "86", label: "86" },
    { value: "87", label: "87" },
    { value: "88", label: "88" },
    { value: "89", label: "89" },
    { value: "90", label: "90" },
    { value: "91", label: "91" },
    { value: "92", label: "92" },
    { value: "93", label: "93" },
    { value: "94", label: "94" },
    { value: "95", label: "95" },
    { value: "96", label: "96" },
    { value: "97", label: "97" },
    { value: "98", label: "98" },
    { value: "99", label: "99" },
    { value: "100", label: "100" }
];
const price = {
    export: {
        a: [
            { value: "0.5", price: "12600" },
            { value: "1", price: "14600" },
            { value: "2", price: "16500" },
            { value: "3", price: "18400" },
            { value: "4", price: "20300" },
            { value: "5", price: "22200" },
            { value: "6", price: "24100" },
            { value: "7", price: "26000" },
            { value: "8", price: "27900" },
            { value: "9", price: "29800" },
            { value: "10", price: "31700" },
            { value: "11", price: "33600" },
            { value: "12", price: "35500" },
            { value: "13", price: "37400" },
            { value: "14", price: "39300" },
            { value: "15", price: "41200" },
            { value: "16", price: "43100" },
            { value: "17", price: "45000" },
            { value: "18", price: "46900" },
            { value: "19", price: "48800" },
            { value: "20", price: "50700" },
            { value: "more", price: "1900" }
        ],
        b: [
            { value: "0.5", price: "13600" },
            { value: "1", price: "15900" },
            { value: "2", price: "18300" },
            { value: "3", price: "20700" },
            { value: "4", price: "23100" },
            { value: "5", price: "25500" },
            { value: "6", price: "27900" },
            { value: "7", price: "30300" },
            { value: "8", price: "32700" },
            { value: "9", price: "35100" },
            { value: "10", price: "37500" },
            { value: "11", price: "39900" },
            { value: "12", price: "42300" },
            { value: "13", price: "44700" },
            { value: "14", price: "47100" },
            { value: "15", price: "49500" },
            { value: "16", price: "51900" },
            { value: "17", price: "54300" },
            { value: "18", price: "56700" },
            { value: "19", price: "59100" },
            { value: "20", price: "61500" },
            { value: "more", price: "2300" }
        ],
        c: [
            { value: "0.5", price: "15600" },
            { value: "1", price: "18100" },
            { value: "2", price: "21000" },
            { value: "3", price: "23900" },
            { value: "4", price: "26800" },
            { value: "5", price: "29700" },
            { value: "6", price: "32600" },
            { value: "7", price: "35500" },
            { value: "8", price: "38400" },
            { value: "9", price: "41300" },
            { value: "10", price: "44200" },
            { value: "11", price: "47100" },
            { value: "12", price: "50000" },
            { value: "13", price: "52900" },
            { value: "14", price: "55800" },
            { value: "15", price: "58700" },
            { value: "16", price: "61600" },
            { value: "17", price: "64500" },
            { value: "18", price: "67400" },
            { value: "19", price: "70300" },
            { value: "20", price: "73200" },
            { value: "more", price: "2900" }
        ]
    },
    import: {
        a: [
            { value: "0.5", price: "14100" },
            { value: "1", price: "16100" },
            { value: "2", price: "18000" },
            { value: "3", price: "19900" },
            { value: "4", price: "21800" },
            { value: "5", price: "23700" },
            { value: "6", price: "25600" },
            { value: "7", price: "27500" },
            { value: "8", price: "29400" },
            { value: "9", price: "31300" },
            { value: "10", price: "33200" },
            { value: "11", price: "35100" },
            { value: "12", price: "37000" },
            { value: "13", price: "38900" },
            { value: "14", price: "40800" },
            { value: "15", price: "42700" },
            { value: "16", price: "44600" },
            { value: "17", price: "46500" },
            { value: "18", price: "48400" },
            { value: "19", price: "50300" },
            { value: "20", price: "52200" },
            { value: "more", price: "2100" }
        ],
        b: [
            { value: "0.5", price: "15100" },
            { value: "1", price: "17400" },
            { value: "2", price: "19800" },
            { value: "3", price: "22200" },
            { value: "4", price: "24600" },
            { value: "5", price: "27000" },
            { value: "6", price: "29400" },
            { value: "7", price: "31800" },
            { value: "8", price: "34200" },
            { value: "9", price: "36600" },
            { value: "10", price: "39000" },
            { value: "11", price: "41400" },
            { value: "12", price: "43800" },
            { value: "13", price: "46200" },
            { value: "14", price: "48600" },
            { value: "15", price: "51000" },
            { value: "16", price: "53400" },
            { value: "17", price: "55800" },
            { value: "18", price: "58200" },
            { value: "19", price: "60600" },
            { value: "20", price: "63000" },
            { value: "more", price: "2300" }
        ],
        c: [
            { value: "0.5", price: "17100" },
            { value: "1", price: "19600" },
            { value: "2", price: "22500" },
            { value: "3", price: "25400" },
            { value: "4", price: "28300" },
            { value: "5", price: "31200" },
            { value: "6", price: "34100" },
            { value: "7", price: "37000" },
            { value: "8", price: "39900" },
            { value: "9", price: "42800" },
            { value: "10", price: "45700" },
            { value: "11", price: "48600" },
            { value: "12", price: "51500" },
            { value: "13", price: "54400" },
            { value: "14", price: "57300" },
            { value: "15", price: "60200" },
            { value: "16", price: "63100" },
            { value: "17", price: "66000" },
            { value: "18", price: "68900" },
            { value: "19", price: "71800" },
            { value: "20", price: "74700" },
            { value: "more", price: "3100" }
        ],
    }
};
export {
    optionsKg,
    price,
    optionsCountry
}