import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {scroller} from 'react-scroll'
import { withStyles } from '@material-ui/core/styles';
import Logo from './exco-logo.png'

import './header_footer.css'
const styles = theme => ({
    header: {
        paddingTop:42,
    },
    item:{
        '&:hover': {
            backgroundColor: 'transparent',
            color:'#FFFFFF'
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color:'#FFFFFF'
        },
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                backgroundColor: 'transparent',
                color:'#FF0000'
            },
            '&:focus': {
                backgroundColor: 'transparent',
                color:'#FF0000'
            },
        },
    },

});
const Header = (props) => {
    const { classes } = props;
    const scrollToElement =(element)=>{
        scroller.scrollTo(element,{
            duration:1000,
            delay:100,
            smooth:true,
            offset:-10
        })
    }
    return (
        <div className="header">
            <AppBar
                position="static"
                className={classes.header}
                style={{
                    background:'transparent',
                    boxShadow:'none'
                }}
            >
                <Toolbar style={{padding:0}} className="header_inner">
                    <div className="header_logo">
                        <div className="logo_img">
                            <img src={Logo} alt="Exco logo"/>
                        </div>
                        <div className="header_logo_title font-bold">
                            Express<br/> <span>courier</span>
                        </div>
                    </div>
                    <List
                        component="nav"
                        style={{
                            display:'flex'
                        }}
                    >
                        <ListItem button className={classes.item} onClick={()=>scrollToElement('about_us')}>
                            Մեր Մասին
                        </ListItem>
                        <ListItem button className={classes.item} onClick={()=>scrollToElement('contact_us')}>
                            Հետադարձ Կապ
                        </ListItem>
                        <ListItem button className={classes.item} onClick={()=>scrollToElement('services')}>
                            Ծառայություններ
                        </ListItem>
                    </List>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default withStyles(styles)(Header);