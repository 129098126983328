import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import {Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios'
import {Contact_data} from "../../fakedata/data"
import {ReactComponent as Tel} from './tel-icon.svg';
import {ReactComponent as Facebook} from './facebook-icon.svg';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MySnackbarContent from '../utils/alertForm'

class Contact extends Component {
    state = {
        open: false,
        isError:true
    };
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };
    render() {
        return (
            <div className="contact_us">
                <h2>{Contact_data.title}</h2>
                <p>{Contact_data.desc}</p>
                <Formik
                    initialValues={{
                        name: '',
                        email: '',
                        tel: '',
                        message: ''
                    }}
                    onSubmit={(values,actions) => {
                        setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            actions.setSubmitting(false);
                        }, 500);
                        // this.setState({ open: true });
                        axios.post('/contact.php',JSON.stringify(values, null, 2))
                            .then( (response)=> {
                                actions.resetForm(false);
                                this.setState({ isError: false,open: true });
                            })
                            .catch( (error) =>{
                                this.setState({ isError: true,open: true });
                                console.log(error,'ERROR');
                            });
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email()
                            .required('Մուտքագրեք էլ-հասցեն'),
                        name: Yup.string()
                            .required('Մուտքագրեք անունը'),
                        message: Yup.string()
                            .required('Ավելացնել հաղորդագրություն'),
                        tel: Yup.string()
                            .min(8, 'Սխալ հեռ․')
                            .required('Մուտքագրեք հեռ․')
                    })}
                >
                    {props => {
                        const {
                            values,
                            touched,
                            errors,
                            // dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            // handleReset,
                        } = props;
                        return (
                            <form onSubmit={handleSubmit}>
                                <div className="contact_us_wrapper">
                                    <div className="contact_us_left">
                                        <div className="contact_us_input_wrap">
                                            <input
                                                id="name"
                                                placeholder="Անուն*"
                                                type="text"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.name && touched.name ? 'text-input error' : 'text-input'
                                                }
                                            />
                                            {errors.name &&
                                            touched.name && <div className="input-feedback">{errors.name}</div>}
                                        </div>
                                        <div className="contact_us_input_wrap">
                                            <input
                                                id="email"
                                                placeholder="Էլ. փոստ*"
                                                type="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.email && touched.email ? 'text-input error' : 'text-input'
                                                }
                                            />
                                            {errors.email &&
                                            touched.email && <div className="input-feedback">{errors.email}</div>}
                                        </div>
                                        <div className="contact_us_input_wrap">
                                            <input
                                                id="tel"
                                                placeholder="Հեռախոս*"
                                                type="number"
                                                value={values.tel}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={
                                                    errors.tel && touched.tel ? 'text-input error' : 'text-input'
                                                }
                                            />
                                            {errors.tel &&
                                            touched.tel && <div className="input-feedback">{errors.tel}</div>}
                                        </div>
                                    </div>
                                    <div className="contact_us_right">
                                    <textarea
                                        id="message"
                                        placeholder="Հաղորդագրություն*"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={
                                            errors.message && touched.message ? 'text-input error' : 'text-input'
                                        }
                                    />
                                        {errors.message &&
                                        touched.message && <div className="input-feedback">{errors.message}</div>}
                                    </div>
                                </div>
                                <div className="social_button">
                                    <div className="form_button_wrapper">
                                        <button type="submit" disabled={isSubmitting}
                                                className="btn_submit contact_us_btn font-medium">
                                            Ուղարկել
                                        </button>
                                    </div>
                                    <div className="social_wrapper">
                                        <ul>
                                            <li>
                                                <span className="contact_us_follow font-bold">Հետևեք մեզ</span>
                                            </li>
                                            <li>
                                                <Button href={Contact_data.facebooksocial} target="_blank" className="social-icon">
                                                    <Facebook/>
                                                </Button>
                                            </li>
                                            <li>
                                                <Button href={`tel:${Contact_data.tel}`} className="social-icon">
                                                    <Tel/>
                                                </Button>
                                                <span className="tel font-medium">{Contact_data.tel}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Formik>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.open}
                    autoHideDuration={2000}
                    onClose={this.handleClose}
                >
                    <MySnackbarContent
                        onClose={this.handleClose}
                        variant={this.state.isError?"error":"success"}
                        message={this.state.isError?"Փորձեք նորից":"Ուղարկված է"}
                    />
                </Snackbar>
            </div>
        );
    }
}

export default Contact;