import React, {Component} from 'react';
import {Formik} from "formik";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import './calculate.css'
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import * as Yup from "yup";
import AlertDialog from './alertDialog'
import MyEnhancedForm from './MyEnhancedForm'
import {withStyles} from '@material-ui/core/styles';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MySnackbarContent from '../utils/alertForm'

function TabContainer(props) {
    return (
        <Typography
            component="div"
        >
            {props.children}
        </Typography>
    );
}

const styles = (theme) => ({
    tabsIndicator: {
        backgroundColor: '#FF0000',
        width: '160px !important',
        [theme.breakpoints.down('xs')]: {
            width: '130px !important',
        },
        [theme.breakpoints.down(320 + theme.spacing.unit * 2 * 2)]: {
            width: '102px !important',
        },
    },
    tabRoot: {
        textTransform: 'initial',
        opacity: 1,
        borderBottom: '2px solid #BBBBBB',
        cursor: 'pointer',
        fontSize: '100px',
        paddingRight: '10px',
        '&:hover': {
            color: '#FF0000',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#FF0000',
        },
        '&:focus': {
            color: '#FF0000',
        },
    },
    tabSelected: {
        // color:'yellow'
        // height:100
    },
});

class Calculate extends Component {
    state = {
        openSnackbar: false,
        errorModal: false,
        value: "one",
        user: {
            open: false,
            size: {},
            price: null,
            topics: [],
            rule: []
        }
    };
    updateUser = user => {
        this.setState({user});
    };
    handleChange = (event, value) => {
        this.setState({value});
    };
    handleClickOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({
            ...this.state,
            user: {
                ...this.state.user,
                open: false
            }
        });
    };
    handleSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openSnackbar: false});
    };
    handleCloseSuccessModal = () => {
        this.setState({
            errorModal: false
        })
    };

    render() {
        const {value, openSnackbar} = this.state;
        const {classes} = this.props;
        return (
            <React.Fragment>

                <div className="calc_wrapper">
                    <div className="calc_wrapper_inner">
                        {this.state.errorModal &&
                        <AlertDialog show={this.state.errorModal}
                                     handleCloseSuccessModal={this.handleCloseSuccessModal}/>}

                        <Dialog
                            open={this.state.user.open}
                            onClose={this.handleClose}
                            aria-labelledby="max-width-dialog-title"
                            maxWidth="md"
                            fullWidth={true}
                            scroll="body"
                            PaperProps={{className: 'calc_wrapper_dialog'}}
                        >
                            <DialogContent
                                className="calc_dialog_content"
                            >
                                <Formik
                                    initialValues={{
                                        exportimport: this.state.value === 'one' ? 'Export' : 'Import',
                                        country: this.state.user.size.label,
                                        name: "",
                                        email: "",
                                        tel: "",
                                        topics: this.state.user.topics,
                                        rule: this.state.user.rule
                                    }}
                                    onSubmit={(values, actions) => {

                                        setTimeout(() => {
                                            // alert(JSON.stringify(values, null, 2));
                                            actions.setSubmitting(false);
                                        }, 500);

                                        axios.post(
                                            "/orders.php",
                                            JSON.stringify(values, null, 2)
                                        )
                                            .then((response) => {
                                                this.setState({
                                                    ...this.state,
                                                    errorModal: true,
                                                    user: {
                                                        ...this.state.user,
                                                        open: false
                                                    }
                                                });
                                            })
                                            .catch((error) => {
                                                this.setState({ openSnackbar: true });
                                                console.log(error, "error");
                                            })
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string()
                                            .email('Մուտքագրեք ճիշտ էլ-հասցեն')
                                            .required("Մուտքագրեք էլ-հասցեն"),
                                        // .min(6,'Min num 6')
                                        name: Yup.string().required("Մուտքագրեք անունը"),
                                        // tel: Yup.string()
                                        // .required("Please tel")
                                        tel: Yup.string()
                                        // .default(0)
                                            .min(8, `Մուտքագրեք հեռ․`)
                                            .required("Մուտքագրեք հեռ․"),
                                        // .positive('Մուտքագրեք հեռ․')
                                        // .integer('Մուտքագրեք հեռ․'),
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            // dirty,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            // handleReset
                                        } = props;

                                        return (
                                            <form onSubmit={handleSubmit}>
                                                <div>
                                                    <div className="calc_exp_imp">
                                                        <h1
                                                            className="exp-imp-title font-bold">
                                                            {this.state.value === 'one' ? 'Արտահանում' : 'Ներմուծում'}
                                                            <span>&#x203A;</span>
                                                            {Object.entries(this.state.user.size).length !== 0 &&
                                                            this.state.user.size.label}
                                                        </h1>
                                                        <button
                                                            type="button"
                                                            className="calc_btn_del"
                                                            onClick={this.handleClose}
                                                        >
                                                            x
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="calc_exp_imp_main_content">
                                                    <Grid container spacing={24}>
                                                        {this.state.user.rule.map((item, indx) => {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={6}
                                                                    key={indx}
                                                                    className={this.state.user.rule.length > 1 ? 'calc_more_item' : 'calc_one_item'}>
                                                                    {/*<h1>{this.state.user.topics[indx].price}</h1>*/}
                                                                    <h4 className="calc_exp_imp_single_title font-medium"> {indx + 1} Հարցում</h4>
                                                                    <div className="calc_exp_imp_single font-medium">
                                                                        <span className="title">բարձր.</span><span
                                                                        className="count">{item.num1}</span></div>
                                                                    <div className="calc_exp_imp_single font-medium">
                                                                        <span className="title">երկար.</span><span
                                                                        className="count">{item.num2}</span></div>
                                                                    <div className="calc_exp_imp_single font-medium">
                                                                        <span className="title">լայն.</span><span
                                                                        className="count">{item.num3}</span></div>
                                                                    <div className="calc_exp_imp_single font-medium">
                                                                        <span className="title">քաշ</span><span
                                                                        className="count">{item.select.value}</span>
                                                                    </div>
                                                                </Grid>
                                                            );
                                                        })}
                                                    </Grid>
                                                </div>
                                                <Grid container spacing={8}>
                                                    <Grid item xs={12} sm={4}>
                                                        <div className="name-classes">
                                                            <input
                                                                id="name"
                                                                placeholder="Անուն*"
                                                                type="text"
                                                                value={values.name}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.name && touched.name
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                }
                                                            />
                                                            {errors.name && touched.name && (
                                                                <div className="input-feedback">{errors.name}</div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <div className="email-classes">
                                                            <input
                                                                id="email"
                                                                placeholder="Էլ. փոստ*"
                                                                type="email"
                                                                value={values.email}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                className={
                                                                    errors.email && touched.email
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                }
                                                            />
                                                            {errors.email && touched.email && (
                                                                <div className="input-feedback">{errors.email}</div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={12} sm={4}>
                                                        <div className="tel-classes">
                                                            <input
                                                                id="tel"
                                                                placeholder="Հեռախոս*"
                                                                type="number"
                                                                value={values.tel}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                // min="1"
                                                                className={
                                                                    errors.tel && touched.tel
                                                                        ? "text-input error"
                                                                        : "text-input"
                                                                }
                                                            />
                                                            {errors.tel && touched.tel && (
                                                                <div className="input-feedback">{errors.tel}</div>
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <div className="calc_modal_footer">
                                                    <div className="calc_modal_footer_inner">
                                                        <div className="calc_total_title font-medium">
                                                            Արժեքը
                                                        </div>
                                                        <div className="calc_total_price font-bold">
                                                            {this.state.user.topics.length > 0 &&
                                                            this.state.user.topics.reduce(
                                                                (a, b) => {
                                                                    const a_price =
                                                                        typeof a === "object"
                                                                            ? Number(a.price)
                                                                            : Number(a);
                                                                    const b_price =
                                                                        typeof b === "object"
                                                                            ? Number(b.price)
                                                                            : Number(b);
                                                                    return a_price + b_price;
                                                                },
                                                                {price: 0}
                                                            )}
                                                            <span> 	&#1423;</span>
                                                        </div>
                                                        <button type="submit" disabled={isSubmitting}
                                                                className="btn_submit calc_modal_submit font-medium">
                                                            Հարցում
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </DialogContent>

                        </Dialog>
                        <AppBar position="static"
                                style={{
                                    backgroundColor: 'transparent'
                                }}
                                className="calc_tabs font-bold"
                        >
                            <Tabs value={value} onChange={this.handleChange}
                                  classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                            >
                                <Tab value="one" label="Արտահանում" className={'calc_tab '}
                                     classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                />

                                <Tab value="two" label="Ներմուծում" className={'calc_tab '}
                                     classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                />
                            </Tabs>
                        </AppBar>
                        {value === "one" && (
                            <TabContainer>
                                <MyEnhancedForm
                                    user={this.state.user}
                                    exp={this.state.value}
                                    size={this.state.user.size}
                                    updateUser={this.updateUser}
                                />
                            </TabContainer>
                        )}
                        {value === "two" && (
                            <TabContainer>
                                <MyEnhancedForm
                                    user={this.state.user}
                                    exp={this.state.value}
                                    size={this.state.user.size}
                                    updateUser={this.updateUser}
                                />
                            </TabContainer>
                        )}
                    </div>

                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={openSnackbar}
                    autoHideDuration={2000}
                    onClose={this.handleSnackbar}
                >
                    <MySnackbarContent
                        onClose={this.handleSnackbar}
                        variant={"error"}
                        message={"Փորձեք նորից"}
                    />
                </Snackbar>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Calculate);