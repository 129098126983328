import React, {Component} from 'react';
import './main.css';
import Header from "./Components/header_footer/header";
import AboutUs from "./Components/about_us";
import Services from "./Components/services";
import Contact from "./Components/contact_us";
import Footer from "./Components/header_footer/footer";
import Calculate from './Components/calculate'
import {Element} from 'react-scroll'
// import {Helmet} from "react-helmet";
// import BigImg from './fakedata/exco-big.jpg'
class Home extends Component {
    render() {
        return (
            <div className="main_page">
                <div className="left_content">
                </div>
                <div className="right_content">
                    <Header/>
                    <Calculate/>
                    <div className="main_content">
                        <Element name="about_us">
                            <AboutUs/>
                        </Element>
                        <Element name="services">
                            <Services/>
                        </Element>
                        <Element name="contact_us">
                            <Contact/>
                        </Element>
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
