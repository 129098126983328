const About_Us_data = {
    "title": "Մեր Մասին",
    "desc": "Էքսպրես Կուրիեր ընկերությունը  հանդիսանում է շուկայում առաջատար բեռնափոխադրող ընկերություն, որը կազմակերպում  է հաճախորդների բեռների արտահանման եւ ներմուծման ողջ գործընթացը: Մենք համագործակցում ենք միջազգային առաջատար գործընկերների հետ, ինչը հնարավորություն է ընձեռնում կատարել ստանձնած պարտականությունները ամենասեղմ ժամկետներում և պատշաճ որակով։ Սա մեզ համար բացառիկ  հնարավորություն է ընձեռնում  համագործակցելու և ծառայություններ մատուցելու ՀՀ առաջատար ընկերություններին, ընկերությունների, որոնք իրենց ընտրությունը կատարել են  ի օգուտ փաստաթղթերի, փաթեթների և ծանրոցների՝ հուսալի և ժամանակակից առաքման ծառայության:\n" +
       "\n" +
        "\"Էքսպրես Կուրիեր\" ՍՊԸ-ի սուրհանդակային և փոստային ծառայությունների մատուցումը լիցենզավորված է ՀՀ տրանսպորտի և կապի նախարարության կողմից:\n" +
        "\n" +
        "Ընկերությունը բեռնափոխադրման որակյալ ծառայություններ է մատուցում ՀՀ –ից դեպի աշխարհի 220 երկիր և տարածք ինչպես նաև հակառակ ուղղությամբ, սակայն այսքանով մեր ծառայությունների աշխարհագրական քարտեզը չի ավարտվում, մեր հաճախորդները հնարավորություն ունեն շահավետ պայմաններով կազմակերպել իրենց բեռնափոխադրումները աշխարհի ցանկացած կետից դեպի ցանկացած կետ: \n" +
        "Մենք տարիներ շարունակ եղել ենք այս ոլորտում եւ ապահովել պրեմիում ծառայություններ տարբեր երկրներում բազմաթիվ հաճախորդների համար: Մենք բարձր ենք գնահատում հաճախորդների գոհունակությունը և մեզ վստահելու պատրաստակամությունը:\n" +
        "\n" +
        "Էքսպրես Կուրիեր ընկերության հիմնական սկզբունքներն են՝  հուսալիություն, արագություն և անհատական մոտեցում:\n"
};
const Service_data = {
    "title": "Ծառայություններ",
    "desc":"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus adipisci aperiam architecto dolorem numquam qui voluptas voluptate voluptatem. Deleniti officia omnis placeat vel? A asperiores autem cupiditate debitis doloribus exercitationem facere iure iusto laborum, maiores neque non quam reprehenderit, sapiente suscipit veniam voluptas? Ab harum in reiciendis similique totam. Accusamus, est eveniet expedita fuga illum laborum magnam officia porro repellendus repudiandae, veniam, veritatis. Alias cumque eum ipsa iusto repudiandae veniam. Ab aspernatur beatae culpa, dignissimos distinctio doloribus explicabo fugit magni molestias mollitia nam obcaecati ratione vitae? Architecto cumque distinctio eius ipsam.",
    "service": [
        {
            "id":1,
            "title": "Օդային բեռնափոխադրում",
            "desc":"Բեռնափոխադրում աշխարհի ցանկացած կետ և հակառակ ուղղությամբ \n" +
                "Փորձված և բանիմաց մասնագետներ\n" +
                "Բեռին առցանց հետևելու հնարավորություն\n"
        },
        {
            "id":2,
            "title": "Էքսպրես բեռնափոխադրում",
            "desc":"Ծանրոցների և ծրարների գերարագ տեղափոխում աշխարհի ցանկացած կետ դռնից դուռ սկզբունքով\n" +
                "Փաթեթավորման որակյալ ծառայություն, որի արժեքը ներառված է տեղափոխման գնի մեջ\n" +
                "Մաքսային և բրոքերային ծառայություններ\n"
        },
        {
            "id":3,
            "title": "Ցամաքային բեռնափոխադրում",
            "desc":"Հավաքական և ամբողջական բեռների տեղափոխում հնարավոր բոլոր ուղղություններով, ինչպես նաև բեռնափոպադրումներ ՀՀ տարացքից դուրս "
        },

    ]
};

const Contact_data = {
    "title": "Հետադարձ Կապ",
    "desc": "Խնդրում ենք փոստով ուղարկել Ձեր հարցերը, բողոքները կամ առաջարկությունները մեր Ծառայությունների վերաբերյալ: \n" +
         "\n" +
        "Կապ  հաստատելու համար խնդրում ենք լրացնել ձեր տվյալները ստորեւ բերված պատուհաններում։ Էքսպրես կուրիեր ընկերությունը  երաշխավորում է Ձեր տվյալների գաղտնիությունը , Ձեր տվյալները կօգտագործվեն միայն ծառայության որակի բարձացման նպատակով և  չեն կիսվի  երրորդ անձանց հետ: Լրացուցիչ տեղեկությունների համար կարող եք կապ հաստատել ստորև նշված հեռախոսահամարով։\n",
    "tel":'+37441511515',
    "facebooksocial":"https://web.facebook.com/EXCOOfficial/"
};
export {
    About_Us_data,
    Contact_data,
    Service_data
}