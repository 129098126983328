import {Component} from "react";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import React from "react";
import Logo from "../header_footer/exco-logo.png";

class AlertDialog extends Component {
    state = {
        open: this.props.show,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.handleCloseSuccessModal()
    };

    render() {
        return (
            <div className="alert_dialog">
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="md"
                    fullWidth={true}
                    // scroll="body"
                >
                    <DialogContent>
                        <div className="alert_dialog_main_content">
                            <img src={Logo} alt="Exco logo"/>
                            <span className="alert_dialog_line"/>
                            <p className="font-medium alert_dialog_content">
                                Շնորհակալություն հարցման համար։
                                <br/>
                                Մեր աշխատակիցները շուտով կապ կհաստատեն Ձեզ հետ։
                            </p>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
export default AlertDialog